

import axios from '@/libs/axios';

export default {
    namespaced: true,
    state: {        
        customers: [],
        customerMap: {},
        ready: false,
    },
    getters: {
        customers: (state) => state.customers,
        customerMap: (state) => state.customerMap,
        isCustomerReady: (state) => state.ready,
    },
    mutations: {        
        setCustomers(state, customers) {             
            let _customerMap = { }
            let _customers = [ ]
            customers.forEach((customer)=>{
                _customers.push(customer)
                _customerMap[customer.id] = customer
            })
            state.ready = true         
            state.customers   = _customers
            state.customerMap = _customerMap
        },
        updateCustomer(state, customer) {
            Object.assign(state.customerMap[customer.id],customer)
        },
        addCustomer(state, customer) {
            state.customers.push(customer)
            state.customerMap[customer.id] = customer
        }        
    },
    actions: {
        async loadCustomers({commit}) {
            return axios.get('/customer/browse').then((response) => {                
                commit("setCustomers", response.data.data)
            }).catch((error)=>{
                console.log(error)
            })
        },
        async updateCustomer({commit}, customer) {
            console.log(customer)            
            return axios.put('/customer', customer).then((response) => {
                console.log(response.data.data)
                commit("updateCustomer", response.data.data)
            })
        },
        async createCustomer({commit}, customer) {
            if ('id' in customer) delete customer['id']
            return axios.post('/customer', customer).then((response) => {
                console.log(response.data.data)
                commit("addCustomer", response.data.data)
            })
        }
    },
}
