import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import web3 from './web3'
import logs from './logs'

import JokerCharlieVoucher from './contract/JokerCharlieVoucher'
import PublicPrivateVoucherMint from './contract/PublicPrivateVoucherMint'

import JCCDContract from './jccd/JCCDContract'
import JCCDCustomer from './jccd/JCCDCustomer'
import JCCDVoucher from './jccd/JCCDVoucher'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    web3,
    JokerCharlieVoucher,
    PublicPrivateVoucherMint,
    JCCDContract,
    JCCDCustomer,
    JCCDVoucher,
    logs
  },
  strict: process.env.DEV,
})
