// import BurnerConnectProvider from "@burner-wallet/burner-connect-provider";
// import Authereum from "authereum";

const state = {
    logs : []
};

const getters = {
    logs: (state) => state.event
};

const actions = {
    logToast({commit}, type, title, message) {
        commit("pushEvent", { type: type, title: title, message: message })
    },
    logSuccess({commit}, title, message) {
        commit("pushEvent", { type: "success", title: title, message: message })
    },
    logError({commit}, title, message) {
        commit("pushEvent", { type: "error", title: title, message: message })
    },
    logInfo({commit}, title, message) {
        commit("pushEvent", { type: "info", title: title, message: message })
    }
};

const mutations = {
    pushEvent(state, value) {
        state.logs.unshift(value)
    } 
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
