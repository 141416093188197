
import { ethers } from 'ethers';

import Contract from '@/contract/JokerCharlieVoucher.json'

export default {
    namespaced: true,
    state: {        
        contract: null,
        message: "",
        maps: {
            JokerType: { },
            VoucherStatus: { }
        },
        choices: {
            JokerType: [ ],
            VoucherStatus: [ ]
        },
        params: {
            chainId: 0,
            activeAccount: 0,
        }
    },
    getters: {
        getContract: (state) => state.contract,
        getContractMessage: (state) => state.message,
        JokerTypeChoice: (state) => state.choices.JokerType,
        VoucherStatusChoice: (state) => state.choices.VoucherStatus,
        JokerTypeMap: (state) => state.maps.JokerType,
        VoucherStatusMap: (state) => state.maps.VoucherStatus
    },
    mutations: {
        setMessage (state, val) { state.message = val },
        setContract(state, val) { state.contract = val },
        setJokerType(state, val) {
            let choices = []
            let maps =  { }
            for (let i=0; i < val.length; i++) {
                choices.push({ value: i, text: val[i]})
                maps[i] = val[i]
            }
            state.choices.JokerType = choices
            state.maps.JokerType = maps
        },
        setVoucherStatus(state, val) {
            let choices = []
            let maps = { }
            for (let i=0; i < val.length; i++) {
                choices.push({ value: i, text: val[i]})
                maps[i] = val[i]
            }
            state.choices.VoucherStatus = choices
            state.maps.VoucherStatus = maps
        },
        setParams(state, val) {
            state.params.chainId = val.chainId
            state.params.activeAccount = val.activeAccount
        }
    },
    actions: {
        async initContract({commit, rootState}) {
            if (!rootState.web3.isConnected) {
                commit("setMessage" , "")
                commit("setContract", null)          
                commit("setParams", rootState.web3)
                return;
            }                        
            if ( rootState.web3.chainId == rootState.JokerCharlieVoucher.params.chainId && 
                  rootState.web3.activeAccount == rootState.JokerCharlieVoucher.params.activeAccount ) return;            
            try {            
                let chainId  = parseInt(rootState.web3.chainId)    
                let signer   = rootState.web3.providerEthers.getSigner()              
                let contract = new ethers.Contract(Contract.network[chainId].address, Contract.abi, signer)                
                let jokerType = await contract.getJokerTypeName()
                let voucherStatus = await contract.getVoucherStatusName()                
                commit("setContract", contract)
                commit("setJokerType", jokerType)
                commit("setVoucherStatus", voucherStatus)
                commit("setParams", rootState.web3)
            } catch {
                commit("setMessage" , "Network is not support")
                commit("setContract", null)                
                commit("setParams", rootState.web3)
            }
        }
    },
}
