

import axios from '@/libs/axios';

export default {
    namespaced: true,
    state: {        
        contracts: [],
        contractMap: {},        
        ready: false,
    },
    getters: {
        contracts: (state) => state.contracts,
        contractMap: (state) => state.contractMap,
        isContractReady: (state) => state.ready,        
    },
    mutations: {        
        setContracts(state, contracts) {             
            let _contractMap = { }
            let _contracts = [ ]
            contracts.forEach((contract)=>{
                contract.value = contract.id
                contract.text  = contract.name
                _contracts.push(contract)
                _contractMap[contract.id] = contract
            })
            state.ready = true         
            state.contracts = _contracts
            state.contractMap = _contractMap
        },
        updateContract(state, contract) {
            contract.value = contract.id
            contract.text  = contract.name
            Object.assign(state.contractMap[contract.id],contract)
        },
        addContract(state, contract) {
            contract.value = contract.id
            contract.text  = contract.name
            state.contracts.push(contract)
            state.contractMap[contract.id] = contract
        }        
    },
    actions: {
        async loadContracts({commit}) {
            return axios.get('/contract/browse').then((response) => {                
                commit("setContracts", response.data.data)
            }).catch((error)=>{
                console.log(error)
            })
        },
        async updateContract({commit}, contract) {
            console.log(contract)            
            return axios.put('/contract', contract).then((response) => {
                console.log(response.data.data)
                commit("updateContract", response.data.data)
            })
        },
        async createContract({commit}, contract) {
            if ('id' in contract) delete contract['id']
            return axios.post('/contract', contract).then((response) => {
                console.log(response.data.data)
                commit("addContract", response.data.data)
            })
        }
    },
}
